import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Services COREMA</h1>
      <h2>Under construction</h2>
    </div>
  );
}

export default App;
